<template>
  <div>
    <q-toolbar class="q-pr-none q-mb-md justify-end">
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> Filtrar Relatórios </q-tooltip></q-btn
      >
    </q-toolbar>
    <q-intersection transition="slide-down" v-show="filter">
      <q-form @submit.prevent="onFilterReports">
        <q-card flat v-if="filter" bordered class="q-mb-sm">
          <q-toolbar>
            <q-toolbar-title> {{ $t('filter') }} </q-toolbar-title>
          </q-toolbar>
          <q-card-section class="q-pt-none row q-col-gutter-md">
            <div class="col-6">
              <date-input
                buttons
                outlined
                label="Data dos cliques"
                :v-model="params.date"
                @update="(value) => (params.date = value)"
                clearable
                range
                :rules="[(val) => !!val || 'O campo data dos cliques é obrigatório']"
              />
            </div>
            <div class="col-6">
              <div class="row q-col-gutter-sm">
                <div class="col">
                  <q-select
                    v-model="params.displayMode"
                    :options="[
                      {
                        label: 'Por campanha',
                        value: 'campaign'
                      },
                      {
                        label: 'Por afiliado',
                        value: 'affiliate'
                      },
                      {
                        label: 'Por canal',
                        value: 'site'
                      },
                      {
                        label: 'Por dia',
                        value: 'day'
                      },
                      {
                        label: 'Por mês',
                        value: 'month'
                      },
                      {
                        label: 'Por ano',
                        value: 'year'
                      }
                    ]"
                    label="Modo de exibição"
                    :rules="[(val) => !!val || 'O campo de modo de exibição é obrigatório.']"
                    outlined
                  />
                </div>
              </div>
            </div>
            <div class="col-6">
              <select-request
                :v-model="params.advertiser"
                outlined
                endpoint="redirect?app=ADVERTISER&path=/api/get/advertiser"
                option-label="trademark"
                option-value="id"
                label="Nome Fantasia"
                clearable
                @update="(value) => (params.advertiser = value === null ? null : value.id)"
              />
            </div>
            <div class="col-6">
              <select-request
                :v-model="params.campaign"
                outlined
                label="Campanha"
                endpoint="/redirect?app=ADVERTISER&path=/api/get/campaign"
                option-label="name"
                option-value="id"
                @update="(value) => (params.campaign = value === null ? null : value.id)"
              />
            </div>
            <div class="col-12 text-right">
              <q-btn color="primary" label="Limpar" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
              <q-btn color="positive" label="Buscar" no-caps unelevated class="q-px-md" type="submit" />
            </div>
          </q-card-section>
        </q-card>
      </q-form>
    </q-intersection>
  </div>
</template>

<script>
import ReportMixin from '../../mixins/ReportMixin';
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';

export default {
  name: 'HeaderClickReports',
  mixins: [ReportMixin],
  components: { SelectRequest, DateInput },

  data() {
    return {
      filter: true,
      params: {
        advertiser: null,
        campaign: null,
        displayMode: null,
        date: null
      }
    };
  },

  methods: {
    clearFilter() {
      this.params = {
        advertiser: null,
        campaign: null,
        displayMode: null,
        date: null
      };
      this.ActionSetFilterReports();
      this.ActionSetReports();
    },

    onFilterReports() {
      let filterSelect = [];
      if (this.params.advertiser) filterSelect.push(`trademark=${this.params.advertiser}`);
      if (this.params.campaign) filterSelect.push(`id_campaign=${this.params.campaign}`);
      if (this.params.date) {
        let [from, , to] = this.params.date.split(' ');
        from = this.formatToEn(from);
        to = this.formatToEn(to);
        filterSelect.push(`start_date=${from}`);
        filterSelect.push(`end_date=${to}`);
      }
      filterSelect.push(`sort=filter%7Casc`);
      filterSelect.push(`filter=${this.params.displayMode.value}`);

      this.ActionSetFilterReports(filterSelect.join('%26'));
      this.getClickReport();
    }
  }
};
</script>

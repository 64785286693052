var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('q-toolbar',{staticClass:"q-pr-none q-mb-md justify-end"},[_c('q-btn',{staticClass:"q-px-sm q-mx-sm",attrs:{"outline":"","icon":"mdi-filter-outline","color":"primary","no-caps":"","label":"Filtrar"},on:{"click":function($event){_vm.filter = !_vm.filter}}},[_c('q-tooltip',[_vm._v(" Filtrar Relatórios ")])],1)],1),_c('q-intersection',{directives:[{name:"show",rawName:"v-show",value:(_vm.filter),expression:"filter"}],attrs:{"transition":"slide-down"}},[_c('q-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onFilterReports.apply(null, arguments)}}},[(_vm.filter)?_c('q-card',{staticClass:"q-mb-sm",attrs:{"flat":"","bordered":""}},[_c('q-toolbar',[_c('q-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('filter'))+" ")])],1),_c('q-card-section',{staticClass:"q-pt-none row q-col-gutter-md"},[_c('div',{staticClass:"col-6"},[_c('date-input',{attrs:{"buttons":"","outlined":"","label":"Data dos cliques","v-model":_vm.params.date,"clearable":"","range":"","rules":[(val) => !!val || 'O campo data dos cliques é obrigatório']},on:{"update":(value) => (_vm.params.date = value)}})],1),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row q-col-gutter-sm"},[_c('div',{staticClass:"col"},[_c('q-select',{attrs:{"options":[
                    {
                      label: 'Por campanha',
                      value: 'campaign'
                    },
                    {
                      label: 'Por afiliado',
                      value: 'affiliate'
                    },
                    {
                      label: 'Por canal',
                      value: 'site'
                    },
                    {
                      label: 'Por dia',
                      value: 'day'
                    },
                    {
                      label: 'Por mês',
                      value: 'month'
                    },
                    {
                      label: 'Por ano',
                      value: 'year'
                    }
                  ],"label":"Modo de exibição","rules":[(val) => !!val || 'O campo de modo de exibição é obrigatório.'],"outlined":""},model:{value:(_vm.params.displayMode),callback:function ($$v) {_vm.$set(_vm.params, "displayMode", $$v)},expression:"params.displayMode"}})],1)])]),_c('div',{staticClass:"col-6"},[_c('select-request',{attrs:{"v-model":_vm.params.advertiser,"outlined":"","endpoint":"redirect?app=ADVERTISER&path=/api/get/advertiser","option-label":"trademark","option-value":"id","label":"Nome Fantasia","clearable":""},on:{"update":(value) => (_vm.params.advertiser = value === null ? null : value.id)}})],1),_c('div',{staticClass:"col-6"},[_c('select-request',{attrs:{"v-model":_vm.params.campaign,"outlined":"","label":"Campanha","endpoint":"/redirect?app=ADVERTISER&path=/api/get/campaign","option-label":"name","option-value":"id"},on:{"update":(value) => (_vm.params.campaign = value === null ? null : value.id)}})],1),_c('div',{staticClass:"col-12 text-right"},[_c('q-btn',{staticClass:"q-px-md q-mx-md",attrs:{"color":"primary","label":"Limpar","no-caps":"","outline":""},on:{"click":_vm.clearFilter}}),_c('q-btn',{staticClass:"q-px-md",attrs:{"color":"positive","label":"Buscar","no-caps":"","unelevated":"","type":"submit"}})],1)])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
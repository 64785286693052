<template>
  <q-layout>
    <div class="page-header">
      <h4>Relatório de Cliques</h4>
    </div>

    <q-page padding>
      <header-click-reports></header-click-reports>
      <list-click-reports></list-click-reports>
    </q-page>
  </q-layout>
</template>

<script>
import HeaderClickReports from '../../components/reports/HeaderClickReports.vue';
import ListClickReports from '../../components/reports/ListClickReports.vue';

export default {
  components: { HeaderClickReports, ListClickReports },
  name: 'ClickReports'
};
</script>

<template>
  <q-intersection transition="slide-down" v-show="this.show">
    <q-table
      flat
      bordered
      :data="reports"
      row-key="id"
      :columns="columns"
      :pagination="pagination"
      :visible-columns="visibleColumns"
    >
      <template v-slot:top-right>
        <q-btn
          outline
          color="grey-3"
          size="12px"
          text-color="grey-8"
          icon-right="archive"
          class="q-mr-sm q-py-xs"
          no-caps
          @click="exportClickReport()"
          unelevated
        >
          <q-tooltip> Exportar como CSV</q-tooltip>
        </q-btn>
        <q-select
          v-model="visibleColumns"
          multiple
          outlined
          dense
          options-dense
          display-value="Personalizar Colunas"
          emit-value
          map-options
          :options="columns"
          option-value="name"
          options-cover
          style="min-width: 150px"
        >
          <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
            <q-item v-bind="itemProps" v-on="itemEvents">
              <q-item-section>
                <q-item-label v-html="opt.label"></q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle :value="selected" @input="toggleOption(opt)" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </template>

      <template v-slot:body-cell-ctr="props">
        <q-td :props="props">
          {{ `${props.row.ctr.toFixed(2)}%` }}
        </q-td>
      </template>

      <template v-slot:bottom>
        <!-- <pagination-table-reports
                  @change="getClickReport"
                  :data="reqPagination"
                  :pagination="pagination"
                />
                 -->
        <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
          Total:{{ reqPagination.total }}
          <q-space />
          <q-pagination
            unelevated
            v-model="currentPage"
            color="primary"
            :max="reqPagination.last_page"
            :max-pages="6"
            boundary-numbers
            direction-links
          />
        </q-toolbar>
      </template>
    </q-table>
  </q-intersection>
</template>

<script>
import ReportMixin from '../../mixins/ReportMixin';

export default {
  name: 'ListClickReports',
  mixins: [ReportMixin],
  methods: {
    async exportClickReport() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          'redirect?app=report&path=/api/get/clickReport/export_csv?' + this.filterReports
        );
        if (status === 200 || status === 201) {
          console.log(data);
          this.exportTableCsv(data, this.columns, 'click-reports');
          this.successNotify('Exportado com sucesso!');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  created() {
    this.ActionSetFilterReports();
    this.ActionSetReports();
    this.visibleColumns = this.columns.map((column) => column.name);
  },

  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getClickReport(`%26page=${this.currentPage}`, `per_page=${this.pagination.rowsPerPage}`);
      }
    }
  },

  data() {
    return {
      visibleColumns: null,
      columns: [
        {
          name: 'indice',
          label: 'Índice',
          align: 'left',
          field: (row) => (row.indice ? row.indice : row.indice_alternative),
          sortable: true
        },
        {
          name: 'impressoes',
          label: 'Impressões',
          align: 'left',
          field: 'impressoes',
          sortable: true
        },
        {
          name: 'total',
          label: 'Cliques totais',
          align: 'left',
          field: 'total',
          sortable: true
        },
        {
          name: 'visitantes_unicos',
          label: 'Cliques únicos',
          align: 'left',
          field: 'visitantes_unicos',
          sortable: true
        },
        {
          name: 'ctr',
          label: 'CTR %',
          align: 'left',
          field: 'ctr',
          sortable: true
        },
        {
          name: 'cadastros_pendentes',
          label: 'Cadastros Pendentes',
          align: 'left',
          field: 'cadastros_pendentes',
          sortable: true
        },
        {
          name: 'cadastros_aprovados',
          label: 'Cadastros Aprovados',
          align: 'left',
          field: 'cadastros_aprovados',
          sortable: true
        },
        {
          name: 'lcr',
          label: 'LCR %',
          align: 'left',
          field: 'lcr',
          sortable: true
        },
        {
          name: 'vendas_pendentes',
          label: 'Vendas Pendentes',
          align: 'left',
          field: 'vendas_pendentes',
          sortable: true
        },
        {
          name: 'vendas_aprovadas',
          label: 'Vendas Aprovadas',
          align: 'left',
          field: 'vendas_aprovadas',
          sortable: true
        },
        {
          name: 'scr',
          label: 'SCR %',
          align: 'left',
          field: 'scr',
          sortable: true
        },
        {
          name: 'valor_total_pendente',
          label: 'Valor Total Pendente',
          align: 'left',
          field: 'valor_total_pendente',
          sortable: true
        },
        {
          name: 'valor_total_aprovado',
          label: 'Valor Total Aprovado',
          align: 'left',
          field: 'valor_total_aprovado',
          sortable: true
        },
        {
          name: 'cpc_pendente',
          label: 'Comissão Cliques Pendente',
          align: 'left',
          field: 'cpc_pendente',
          sortable: true
        },
        {
          name: 'cpc_aprovado',
          label: 'Comissão Cliques Aprovado',
          align: 'left',
          field: 'cpc_aprovado',
          sortable: true
        },
        {
          name: 'comissao_pendente',
          label: 'Comissão Pendente',
          align: 'left',
          field: 'comissao_pendente',
          sortable: true
        },
        {
          name: 'comissao_aprovada',
          label: 'Comissão Aprovada',
          align: 'left',
          field: 'comissao_aprovada',
          sortable: true
        }
      ],
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      }
    };
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    },
    show() {
      return typeof this.reports === 'undefined' ? false : true;
    }
  }
};
</script>
